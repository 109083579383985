import {getElementById} from './tools'

window.addEventListener('load', ()=>{
  const root = getElementById('positionChart')
  if(!root) {
    return
  }
  const ctl = getElementById('chartControlSelect')
  const doc = getElementById('charts')

  const load = span => {
    positionChart.classList.add('loading')
    fetch(baseUrl+'entry_tags/user/'+ctl.getAttribute('userId')+'?scope=latest&span='+span)
      .then(r=>{
        if(r.ok){
          r.json().then(j=>{
            doc.innerHTML = ''
            j.dataset
              .filter((a,k)=>{
                if(k===0) {
                  return true
                }
                return 0 < a.datasets[0].data.reduce((c,d)=>{return c+d},0)
              })
              .map(ds=>{
                const cfg = {
                    type: 'radar',
                    data: ds,
                    scale: {
                      ticks: {
                        min: 0,
                        stepSize: 1,
                      }
                    },
                    options: {
                      responsive: false,
                      scales: {
                        r: {
                          min: 0
                        }
                      },
                      elements: {
                        line: {
                          borderWidth: 2
                        },
                      },
                      plugins: {
                        title: {
                          display: false,
                        }
                      }
                    }
                  }

                  const e = document.createElement('canvas')
                  e.classList.add('chart')
                  doc.appendChild(e)
                  new Chart(e, cfg)
              })
          })
        }
      })
      .finally(()=>{
        positionChart.classList.remove('loading')
      })
  }

  ctl.addEventListener('change', ev => {load(ev.target.value)})
  load('month')
})
