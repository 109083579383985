'use strict';

import {isOk, getElementById, showHide} from './tools'

window.addEventListener('load', () => {
  const area = getElementById('race_result')
  if(!area) {
    return;
  }

  const btn = getElementById('race_calculation')
  btn.addEventListener('click', (ev) => {
    ev.preventDefault()

    if(window.confirm('レース結果を確定していいですか？\n（確定は取り消せません）')){
      showHide(getElementById('race_spinner'),1)
      const id = btn.getAttribute('event_id',null)
      if(id) {
        fetch(baseUrl+'races/calculation/'+id)
          .then(r => {
            if(isOk(r)){
              showHide(getElementById('calcOK'),1)
              btn.disabled = true
            }
          })
          .finally(()=>{
            showHide(getElementById('race_spinner'),0)
          })
      }
    }
  })
})
