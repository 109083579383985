'use strict';

import './basic'
import './entries'
import './races'
import './charts'
import {lazyload} from './tools'
import 'bootstrap'

window.tippy = require('tippy.js')
window.Vue = require('vue')
window.addEventListener('load', ()=>{
  lazyload()
})
