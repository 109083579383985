'use strict'

import LazyLoad from "vanilla-lazyload"
const l = new LazyLoad()

export const forEach = (a,b) => {for(let i=0;i<a.length;i++){if(b(a[i])===true)break}}
export const isOk = r => {return r.status >= 200 && r.status < 300}
export const getElementById = (i) => {return document.getElementById(i)}
export const showHide = (e,sh) => {
  if(!e){return}
  if(sh){
    e.classList.remove('d-none')
  }else{
    e.classList.add('d-none')
  }
}
export const lazyload = ()=>{
  l.update()
}
