'use strict';

// ソートアイコンの▲逆転
window.addEventListener('load', ()=>{
  var l = document.getElementsByTagName('a');
  for(var i=0;i<l.length;i++){
    if(l[i].text=='▼'&&l[i].href.includes('direction=desc')) {
      l[i].text='▲'
    }
  }
})

window.addEventListener('load', ()=>{
  // ランキングのyear-monthセレクタのy-m分離
  var rf = document.getElementById('rankingFilter')
  if (rf) {
    rf.addEventListener('submit', ev => {
      Array.prototype.forEach.call(
        rf.getElementsByClassName('y-mselect'),
        e => {
          var opt = e.options[e.options.selectedIndex]
          if(!opt){
            return;
          }

          let y = document.createElement('input')
          y.type = 'hidden'
          y.name = 'year'
          y.value = opt.getAttribute('year')
          rf.appendChild(y)

          let m = document.createElement('input')
          m.type = 'hidden'
          m.name = 'month'
          m.value = opt.getAttribute('month')
          rf.appendChild(m)

          e.name = ''
        }
      )
    })
  }

  Array.prototype.forEach.call(
    document.getElementsByClassName('formClean'),
    f => {
      f.addEventListener('submit', ev => {

        // リセット時のinputリセット
        if(ev.submitter.classList.contains('reset')) {
          Array.prototype.forEach.call(
            ev.srcElement.getElementsByTagName('input'),
            e => {
              if(e.type == 'text'||e.type == 'checkbox'||e.type == 'select'||e.type == 'hidden'){
                e.name = ''
              }
            }
          )

          Array.prototype.forEach.call(
            ev.srcElement.getElementsByTagName('select'),
            e => {e.name = ''}
          )
        }

        // フォーム送信時のデフォルト値input削除
        var a = ev.srcElement.getElementsByClassName('cleanner');
        for(var i=0;i<a.length;i++) {
          if(a[i].value==''){a[i].name=''}
        }
      });
    }
  )
});


window.addEventListener('load', ()=>{
  const s = document.getElementById('inputStaffId')
  const res = document.getElementById('staffResult')
  const list = document.getElementById('badIdList')
  if(s&&res&&list){
    let processing = false
    s.addEventListener('change', ()=>{
      if(processing||s.value===''){
        return
      }
      const val = s.value
      res.classList.remove('ok')
      res.classList.remove('warn')
      res.classList.remove('err')
      list.innerHTML = ''
      if(val === s.getAttribute('before')){
        return
      }
      if(val.length < 3) {
        res.classList.add('err')
        return
      }
      processing = true
      res.classList.add('processing')

      fetch('/users/staff/'+val)
        .then(async (r)=>{
          if(r.ok){
            const j = await r.json()
            if(Array.isArray(j)){
              for(const i of res.getElementsByClassName('idname')){
                i.innerHTML = val
              }
              if(j.length === 0) {
                res.classList.add('ok')
              }else{
                res.classList.add('warn')
                for(const z of j) {
                  const li = document.createElement('li')
                  li.innerHTML = `${z.store.name} ${z.name}`
                  list.appendChild(li)
                }
              }
            }
          }
        })
        .finally(()=>{
          processing = false
          res.classList.remove('processing')
        })
    })
  }
})
